import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import Banner from "../components/Content/Banner/Index";
import LeadIn from "../components/Content/LeadIn/Index";
import FeatureColumn from "../components/Content/FeatureColumn/Index";
import Col2 from "../components/Content/TextMedia/Col-2";
import ImageWrapper from "../components/Content/TextMedia/ImageWrapper";
// import Col1 from "../components/Content/TextMedia/Col-1";

// assets
import bannerImage from "../assets/images/banner/landing-tools.jpg";
import brandRomax4000 from "../assets/logos/logo-romax-4000.svg";
import brandRomaxCompact from "../assets/logos/logo-romax-compact-tt.svg";
import romax4000 from "../assets/images/products/tools/tools-romax-4000.jpg";
import romaxCompact from "../assets/images/products/tools/tools-romax-compact-tt-v1.jpeg";
import approvedImage from "../assets/images/products/tools/approved.jpg";
import servicingImage from "../assets/images/products/tools/servicing.jpg";
import hireImage from "../assets/images/products/tools/hire.jpg";

// data
import data from "../pages/static-pages/landing/tools-and-accessories.yaml";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Tools and Accessories"
        description="Get the most out of your press fittings with the right tools &amp; accessories."
      />
      <Banner
        type="landing"
        title="Tools &amp; Accessories"
        summary="Get the most out of your press fittings with the right tools &amp; accessories."
        featureMediaUrl={bannerImage}
        featureMediaTitle="Romax tool in hand used on copper piping"
        removePadding={true}
      />
      <LeadIn
        anchor="romax-compact-tt"
        brandMediaUrl={brandRomaxCompact}
        brandMediaTitle="Romax Compact TT"
        brandMediaWidth="482px"
        content="Compact and light weight, this battery operated electro-hydraulic press machine is perfect for working in tight spaces. Suitable for use on press fittings up to 40mm."
      />
      <ImageWrapper
        imageSrc={romaxCompact}
        zoomSrc={romaxCompact}
        imageAlt="Romax Compact TT with features"
        customBg="#E5E5E5"
      />
      <FeatureColumn
        grid="medium"
        data={data.columns1}
        footnote="<strong>To find out more about the ROMAX Compact TT, visit the Reece website.</strong>"
        buttonValue="www.reece.com.au"
        buttonUrl="https://www.reece.com.au/search/?query=Rothenberger%20Romax%20Compact%20TT%20Results"
        buttonTarget="_blank"
      />
      <LeadIn
        anchor="romax-4000"
        brandMediaUrl={brandRomax4000}
        brandMediaTitle="Romax 4000"
        brandMediaWidth="286px"
        content="Efficient and powerful, this battery operated electro-hydraulic press machine provides installers easy access to a number of systems using one tool – simply change the jaws. Suitable for use on Conex Bänninger press fittings up to 100mm."
      />
      <ImageWrapper
        imageSrc={romax4000}
        zoomSrc={romax4000}
        imageAlt="Romax 4000 with features"
        customBg="#E5E5E5"
      />
      <FeatureColumn
        grid="medium"
        data={data.columns2}
        footnote="<strong>To find out more about the ROMAX 4000, visit the Reece website.</strong>"
        buttonValue="www.reece.com.au"
        buttonUrl="https://www.reece.com.au/search/?query=Rothenberger%20Romax%204000%20Results"
        buttonTarget="_blank"
        buttonRel="noreferrer"
      />
      <LeadIn
        anchor="accessories"
        brandHeading="Accessories"
        divider={true}
        paddingSize="xsmall"
      />
      <FeatureColumn
        grid="medium"
        heading="Jaws"
        summary="You’ve got the tool, now you need the matching heads to make the perfect joint. There are a variety of jaws to suit each of the different Conex Bänninger press systems and sizes."
        columns="4"
        data={data.columns3}
      />
      <FeatureColumn
        grid="medium"
        heading="Preparation, Maintenance &amp; Storage"
        summary="Essential products to guarantee a reliable job, each and every time."
        footnote="<strong>To discover more about these products, visit the Reece website.</strong>"
        buttonValue="www.reece.com.au"
        buttonUrl="https://www.reece.com.au/search/?query=Preparation%20Maintenance%20and%20Storage"
        buttonTarget="_blank"
        buttonRel="noreferrer"
        data={data.columns4}
      />
      <LeadIn
        anchor="approved-tools"
        brandHeading="Approved Tools"
        divider={true}
        paddingSize="xsmall"
      />
      <Col2
        summary="
          <p><strong>The following tools have been tested on and are guaranteed to perform well with >B< Press fittings. Using any other tool can void your Conex Bänninger warranty.</strong></p>
          <ul>
            <li>Rothenberger Romax Compact TT</li>
            <li>Rothenberger Romax 4000</li>
            <li>Rothenberger Romax Compact</li>
            <li>Rothenberger Romax 3000</li>
            <li>Viega Picco</li>
            <li>Viega PT3-AH</li>
            <li>Viega 4B</li>
            <li>Viega 5B</li>
          </ul>
        "
        backgroundMediaUrl={approvedImage}
        backgroundMediaTitle="Person showing tool and fittings"
        mediaPositionClass="right"
        footnote="With strict Australian piping tolerances, it is imperative that your tool works in unison with your press fittings. Therefore, it is recommended to use Rothenberger press tools when installing >B< Press for the best press solution."
      />
      <LeadIn
        anchor="tool-servicing"
        brandHeading="Tool Servicing"
        divider={true}
        paddingSize="xsmall"
      />
      <Col2
        summary="
          <h2>Keep your press tool in top condition</h2>
          <p>Your press tool works hard and just like your car it needs to be serviced annually. Dirt, water, mud and physical damage can all affect the performance of your tool.</p>
          <p>Simply drop your tool into any Reece branch and they will take care of sending it off for service.</p>
          <a href='https://www.reece.com.au/tool-servicing-department' target='_blank' referrer='nofollow noreferrer' class='uk-button uk-button-link'>Find Out More</a>
        "
        backgroundMediaUrl={servicingImage}
        backgroundMediaTitle="Tool being serviced by person"
        mediaPositionClass="left"
      />
      <LeadIn
        anchor="tool-hire"
        brandHeading="Tool Hire"
        divider={true}
        paddingSize="xsmall"
      />
      <Col2
        summary="
          <h2>Access the tools you need with convenience</h2>
          <p>The Reece tool hire service is available at over 500 branches across Australia and offers account customers access to hire a broad range of tools, including Rothenberger press tools and jaws. Hired items can be returned to any Reece branch and they will take care of the rest for you.</p>
          <a href='https://www.reece.com.au/tool-hire/' target='_blank' referrer='nofollow noreferrer' class='uk-button uk-button-link'>Find Out More</a>
        "
        backgroundMediaUrl={hireImage}
        backgroundMediaTitle="Person in read of truck with toolbox"
        mediaPositionClass="right"
      />
    </Layout>
  );
};

export default IndexPage;
